var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('add-edit',{ref:"cronoAdd",attrs:{"is-add":_vm.isAdd},on:{"update:isAdd":function($event){_vm.isAdd=$event},"update:is-add":function($event){_vm.isAdd=$event}}}),_c('b-card',{ref:"filterContent",staticClass:"sticky",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"d-flex flex-column flex-lg-row justify-content-start",attrs:{"md":"12","lg":"4"}},[_c('div',{staticClass:"w-100 mb-1 mb-lg-0 mt-02"},[_c('b-form-group',{staticClass:"mr-2",attrs:{"label":"Proyecto","label-for":"project"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.proyectos,"label":"code","input-id":"project","reduce":function (proyectos) { return proyectos.id; },"placeholder":"Proyecto","disabled":_vm.user_role != 'superadmin'},on:{"input":function($event){return _vm.filter()}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}]),model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}})],1)],1)])],1)],1)],1),_c('b-card',{},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"md":"4"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("FORMULARIOS")]),_c('drop-list',{staticClass:"list1 d-flex flex-column",attrs:{"items":_vm.records,"mode":"cut"},on:{"insert":_vm.onInsertNullFilters,"reorder":function($event){return $event.apply(_vm.records)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.id,staticClass:"item rounded-lg bg-light border border-light-emphasis",attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.records, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.id,staticClass:"item feedback rounded-lg"},[_vm._v(" "+_vm._s(data.name)+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("Externo")]),_c('drop-list',{staticClass:"list1 d-flex flex-column",attrs:{"items":_vm.externColumns,"mode":"cut"},on:{"insert":_vm.onInsertExternFilters,"reorder":function($event){return $event.apply(_vm.externColumns)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.id,staticClass:"item",attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.externColumns, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.id,staticClass:"item feedback rounded-lg"},[_vm._v(" "+_vm._s(data.name)+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("Interno")]),_c('drop-list',{staticClass:"list1 d-flex flex-column",attrs:{"items":_vm.internColumns,"mode":"cut"},on:{"insert":_vm.onInsertInternFilters,"reorder":function($event){return $event.apply(_vm.internColumns)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.id,staticClass:"item",attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.internColumns, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.id,staticClass:"item feedback rounded-lg"},[_vm._v(" "+_vm._s(data.name)+" ")])]}}])})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }